<form class="user-edit-form" [formGroup]="userForm" (submit)="onSubmit()">

  <mat-form-field appearance="fill" [ngClass]="routeAnimationsElements">
    <mat-label>Email</mat-label>
    <input matInput required placeholder="Email" formControlName="email">
    <mat-error *ngIf="userForm.controls.email.hasError('required')">
      You must enter an email address.
    </mat-error>
    <mat-error *ngIf="userForm.controls.email.hasError('email')">
      Please enter a valid email address.
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill" [ngClass]="routeAnimationsElements">
    <mat-label>First Name</mat-label>
    <input matInput required placeholder="First Name" formControlName="firstName">
  </mat-form-field>

  <mat-form-field appearance="fill" [ngClass]="routeAnimationsElements">
    <mat-label>Middle Name</mat-label>
    <input matInput placeholder="Middle Name" formControlName="middleName">
  </mat-form-field>

  <mat-form-field appearance="fill" [ngClass]="routeAnimationsElements">
    <mat-label>Last Name</mat-label>
    <input matInput required placeholder="Last Name" formControlName="lastName">
  </mat-form-field>

  <mat-form-field appearance="fill" *nbIsGranted="['edit', 'application_users']" [ngClass]="routeAnimationsElements">
    <mat-label>Comments</mat-label>
    <textarea rows="5" matInput placeholder="Comments" formControlName="comments"></textarea>
  </mat-form-field>

  <mat-form-field appearance="fill" *nbIsGranted="['edit', 'crm']" [ngClass]="routeAnimationsElements">
    <mat-label>CRM Link</mat-label>
    <input matInput placeholder="CRM Link" formControlName="crmLink">
  </mat-form-field>

  <mat-form-field appearance="fill" *nbIsGranted="['edit', 'org']" [ngClass]="routeAnimationsElements">
    <mat-label>Organisation</mat-label>
    <mat-select placeholder="Organisation" formControlName="organisationId" #organisation>
      <mat-option>
        <idl-filter-select ngModel [ngModelOptions]="{standalone: true}" (ngModelChange)="filterOrg($event)"
          name="orgSelectFilter">
        </idl-filter-select>
      </mat-option>
      <mat-option [value]="org.organisationId" *ngFor="let org of filteredOrganisations | async">
        {{org.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-checkbox formControlName="suppressEmail" [ngClass]="routeAnimationsElements">Suppress Emails</mat-checkbox>

  <ng-template [ngIf]="!editSelf || 1">
    <mat-form-field appearance="fill" *nbIsGranted="['edit', 'roles']" [ngClass]="routeAnimationsElements">
      <mat-label>Roles</mat-label>
      <mat-select multiple placeholder="Roles" formControlName="roles">
        <mat-option [value]="role.name" *ngFor="let role of roles">
          {{role.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox *nbIsGranted="['edit', 'application_users']" formControlName="lockedOut"
      [ngClass]="routeAnimationsElements">Locked Out</mat-checkbox>
  </ng-template>

  <button class="save-button" mat-raised-button color="accent" type="submit" [disabled]="userForm.invalid">
    <div *ngIf="addUser; else buttonElseBlock">Create</div>
    <ng-template #buttonElseBlock>Save</ng-template>
  </button>

</form>