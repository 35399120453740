import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators
} from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UsersService } from '~app/core/data/users.service';
import { NotificationService } from '~core/core.module';
import { LicenseService } from '~core/data/license.service';
import { ChangeLicenseRequest, License } from '~core/models/license.model';

@Component({
  selector: 'idl-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss']
})
export class LicenseDetailsComponent implements OnInit, OnDestroy {
  private readonly _fb = inject(UntypedFormBuilder);
  private readonly _licenseService = inject(LicenseService);
  private readonly _notificationService = inject(NotificationService);
  private readonly _userService = inject(UsersService);

  private unsubscribe$ = new Subject<void>();

  license$: Observable<License>;
  userCRM$: Observable<string>;
  additionalEmails: string[];

  @Input() licenseId: number;

  detailsForm = this._fb.group({
    licenseGuid: [],
    licenseHolderName: [],
    applicationName: [],
    type: [],
    expirydate: [],
    emails: new UntypedFormArray([]),
    comments: [],
    creationdate: [],
    createdByUserName: [],
    metadataSetName: [],
    licenseData: []
  });

  ngOnInit() {
    if (this.licenseId) {
      this.license$ = this._licenseService.get(this.licenseId).pipe(
        tap(license => {
          this.additionalEmails =
            license.additionalEmails?.length > 0
              ? license.additionalEmails?.split(',')
              : [];

          this.detailsForm.patchValue({
            licenseGuid: license.licenseGuid,
            licenseHolderName: license.licenseHolderName,
            applicationName: license.applicationName,
            type: license.type,
            expirydate: license.expirydate,
            comments: license.comments,
            creationdate: license.creationdate,
            createdByUserName: license.createdByUserName,
            metadataSetName: license.metadataSetName,
            licenseData: license.licenseData
          });

          this.additionalEmails.forEach(e => {
            const form = new UntypedFormControl(e, Validators.email);
            (<UntypedFormArray>this.detailsForm.controls['emails']).push(form);
          });
        })
      );

      this.userCRM$ = this.license$.pipe(
        switchMap(license => this._userService.get(license.licenseHolderId)),
        tap(console.log),
        map(user => user?.crmLink)
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  AddAdditionalEmail() {
    const form = new UntypedFormControl('', Validators.email);
    (<UntypedFormArray>this.detailsForm.controls['emails']).push(form);
  }

  DeleteAdditionalEmail(index: number) {
    (<UntypedFormArray>this.detailsForm.controls['emails']).removeAt(index);
  }

  updateComments() {
    const changeRequest = new ChangeLicenseRequest(
      this.detailsForm.controls['comments'].value,
      null
    );
    this._licenseService
      .change(this.licenseId, changeRequest)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this._notificationService.success('License updated.');
      });
  }

  updateEmails() {
    const additionalEmails = (<UntypedFormArray>(
      this.detailsForm.controls.emails
    )).controls
      .map(c => c.value)
      .join();

    const changeRequest = new ChangeLicenseRequest(null, additionalEmails);
    this._licenseService
      .change(this.licenseId, changeRequest)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this._notificationService.success('License updated.');
      });
  }

  copyInput(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this._notificationService.success('License data copied to clipboard.');
  }

  NavigateUserCRM(url: string) {
    console.log(url);
    window.open(url, '_blank');
  }
}
