@if (license$ | async; as license) {
<form #licenseForm="ngForm" [formGroup]="detailsForm">
  <mat-form-field class="full-width">
    <mat-label>License GUID</mat-label>
    <input matInput readonly placeholder="License GUID" formControlName="licenseGuid">
  </mat-form-field>

  <div class="full-width flex">
    <mat-form-field class="flex-grow">
      <mat-label>License Holder Name</mat-label>
      <input matInput readonly placeholder="License Holder Name" formControlName="licenseHolderName">
    </mat-form-field>
    @if (userCRM$ | async; as crmLink) {
    <button *nbIsGranted="['view', 'users']" class="form-button" type="button" mat-raised-button color="accent" (click)="NavigateUserCRM(crmLink)">User
      CRM</button>
    }
  </div>

  <mat-form-field class="full-width">
    <mat-label>Application</mat-label>
    <input matInput readonly placeholder="License Holder Name" formControlName="applicationName">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Type</mat-label>
    <input matInput readonly placeholder="Type" formControlName="type">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Expiry Date</mat-label>
    <input matInput readonly placeholder="Expiry Date" formControlName="expirydate">
  </mat-form-field>

  <button class="form-button" type="button" mat-raised-button color="accent" (click)="AddAdditionalEmail()">Add
    Additional Email</button>

  <div formArrayName="emails">
    <mat-form-field *ngFor="let f of (detailsForm.controls.emails)['controls'];let i=index" appearance="fill"
      class="full-width">
      <mat-label>Email</mat-label>
      <input matInput placeholder="Email" [formControlName]="i">
      <button mat-icon-button matSuffix (click)="DeleteAdditionalEmail(i)" [attr.aria-label]="'Remove Email'"
        [attr.aria-pressed]="delete">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <button class="form-button"
    *ngIf="(detailsForm.controls.emails)['controls'].length > 0 || additionalEmails.length > 0"
    [disabled]="detailsForm.invalid" type="button" mat-raised-button color="accent" (click)="updateEmails()">Update
    Additional Emails</button>

  <mat-form-field class="full-width" *nbIsGranted="['view', 'license_comments']">
    <mat-label>Comments</mat-label>
    <textarea rows="5" matInput placeholder="Comments" formControlName="comments"></textarea>
    <button mat-raised-button color="accent" (click)="updateComments()">Update Comments</button>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Creation Date</mat-label>
    <input matInput readonly placeholder="Creation Date" formControlName="creationdate">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Created By</mat-label>
    <input matInput readonly placeholder="Created By" formControlName="createdByUserName">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Metadata Set</mat-label>
    <input matInput readonly placeholder="Metadata Set" formControlName="metadataSetName">
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>License Data</mat-label>
    <button mat-icon-button matSuffix matTooltip="Copy license to clipboard." (click)="copyInput(licenseData)">
      <mat-icon>content_copy</mat-icon>
    </button>
    <textarea rows="20" matInput readonly placeholder="License Data" formControlName="licenseData"
      #licenseData></textarea>
  </mat-form-field>
</form>
}