@if (roles$ | async; as roles) {
  <mat-table [dataSource]="dataSource">
  
    <!-- Date Column -->
    <ng-container matColumnDef="applicationName">
      <mat-header-cell *matHeaderCellDef> Application </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Application:</span>
        {{element.applicationName }}
      </mat-cell>
    </ng-container>
  
    <!-- IP Address Date Column -->
    <ng-container matColumnDef="roleName">
      <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="mobile-label">Role:</span>
        {{element.roleName}}
      </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  
  </mat-table>
}
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
