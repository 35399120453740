import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  inject,
  Input,
  ViewChild
} from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UsersService } from '~app/core/data/users.service';
import { AppUserRoleDto } from '~app/core/models/app-user-role-dto';
import { User } from '~app/core/models/user.model';

@Component({
  selector: 'idl-user-app-roles',
  templateUrl: './user-app-roles.component.html',
  styleUrls: ['./user-app-roles.component.scss'],
  standalone: true,
  imports: [CommonModule, MatPaginatorModule, MatSortModule, MatTableModule]
})
export class UserAppRolesComponent implements AfterViewInit {
  private readonly _usersService = inject(UsersService);

  @Input({ required: true }) user: User;

  roles$ = new Observable<AppUserRoleDto[]>();
  dataSource = new MatTableDataSource<AppUserRoleDto>();

  displayedColumns: string[] = ['applicationName', 'roleName'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.roles$ = this._usersService.getAppRoles(this.user.userId).pipe(
      tap(roles => {
        this.dataSource.data = roles;
      })
    );
  }
}
