import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DayjsDateModule } from '~app/core/utils/material-dayjs-adapter/material-dayjs-adapter.module';
import { ProgressDialogComponent } from '~app/core/utils/progress-dialog/progress-dialog.component';
import { MaterialModule } from './material-module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    DayjsDateModule,

    TranslateModule,

    ProgressDialogComponent
  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    TranslateModule,

    MaterialModule,
    DayjsDateModule,
    ProgressDialogComponent
  ],
  providers: []
})
export class SharedModule {
  constructor() {}
}
