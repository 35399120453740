@if (user) {
<form class="user-app-settings-form" (ngSubmit)="onSubmit()" [formGroup]="form">
  <div *ngFor="let setting of user.applicationSettings">
    <mat-form-field appearance="fill">
      <mat-label>{{setting.applicationName}}</mat-label>
      <mat-select [id]="setting.applicationId.toString()" [formControlName]="setting.applicationId">
        <mat-option *ngFor="let opt of options" [value]="opt">{{opt}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <button mat-raised-button class="save-button" color="accent" (click)="onSubmit()"
    [disabled]="!form.valid">Save</button>
</form>
}