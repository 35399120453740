export class AppUserRoleDto {
  constructor(
    public userId: number,
    public applicationId: number,
    public applicationName: string,
    public roleId: number,
    public roleName: string
  ) {}

  static adapt(item: any): AppUserRoleDto {
    return new AppUserRoleDto(
      +item.userId,
      +item.applicationId,
      item.applicationName,
      +item.roleId,
      item.roleName
    );
  }
}
