import { AppUserRoleDto } from './app-user-role-dto';
import { ResponseInterface } from './types';

export class ListAppUserRoles {
  constructor(public roles: AppUserRoleDto[]) {}

  static adapt(item: any): ListAppUserRoles {
    return new ListAppUserRoles(item.roles.map(AppUserRoleDto.adapt));
  }
}

export class AppUserRolesResponse implements ResponseInterface {
  status = '';
  data: ListAppUserRoles;
  constructor() {}
}
